$body-bg: #fff;




@import './scss/sb-admin-2.css';

html{ height:100%; }
body{ min-height:100%; padding:0; margin:0; position:relative; }

body::after{ content:''; display:block; height:100px; }

footer{
  position:absolute;
  bottom:0;
  width:100%;
  height:100px;
}

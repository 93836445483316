.product {
  padding-bottom: 2rem;
  &__img {
    width: 80%;
    height: 80%;
  }

  &__text {
    flex: 0 0 40%;
  }

  &__link {
    flex: 0 0 50%;
    height: 50%;
    padding: 1rem;
    position: relative;
  }

  &__title {
    font-size: .5rem;
  }

  &__price {

  }

  &__description {
    font-size: 0.7rem;
  }

  &__name {
    font-size: 0.8rem;
    margin-top: 4em;
  }

  &__descriptionheight {
    height: 100px;
  }

  &:hover {
    box-shadow: 0 1rem 2rem rgba(0,0,0, .2);
  }

  &__add-to-cart {
    position: absolute;
    bottom: .8rem;
    width: 60%;
    left: 50%;
    opacity: 0;
    visibility: hidden;
    transform: translateX(-50%);
    transition: all .2s;
    padding: 3px 3px;

  }

  &:hover &__add-to-cart {
    visibility: visible;
    opacity: 1;
  }

  &:hover .owl-dots {
    display: inline-block;
  }

}

@media only screen and (max-width: 768px) {
  .product {
    height: auto !important;
    &__add-to-cart {
      visibility: visible;
      opacity: 1;
    }

    & .owl-dots {
      display: inline-block;
    }
  }
}
.custom-radio-btn {
  display: block;
  position: relative;
  cursor: pointer;
  padding-left: 2rem;
  user-select: none;

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  &__span {
    position: absolute;
    top: 50%;
    left: 0;
    height: 1.3rem;
    width: 1.3rem;
    background-color: #eeeeee;
    border-radius: 12px;
    transform: translateY(-50%);

    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: .7rem;
      height: .7rem;
      border-radius: 50%;
      background-color: dodgerblue;
      content: "";
      display: none;
    }
  }

  &__input:checked ~ &__span::after {
    display: block;
  }

  &:hover &__span {
    background-color: #dfdfdf;
  }


}

.text-remove-selected {
  font-size: 1rem;
  font-weight: normal;
  cursor: pointer;
  padding-left: 2rem;
}